export const REACT_APP_WEB_ROOT_PATH = process.env.REACT_APP_WEB_ROOT_PATH || "";

export const VERSION = "2025.1.0"

export const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === "true";
export const IS_DEVELOP = process.env.REACT_APP_IS_DEVELOP === "true"
export const WELCOME_SCREEN_COOKIE = "WELCOME_SCREEN_COOKIE_VMSP"
export const MAX_IP_COUNT = 20000
export const IS_API_MOCKED = process.env.REACT_APP_ENABLE_MSW === "true"

const baseUrl = window.location.protocol + "//" + window.location.host;
export let BASEURL = baseUrl;
if (IS_LOCAL) {
    BASEURL = "https://gcs-dev-mgmt.greenbone.io"
}

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY

export const MAILSERVER_IP_ADDRESSES = ["212.132.76.189", "212.132.79.77", "212.132.80.117"];
