import React from "react"
import {Col, Container, Row} from "reactstrap"
import {globalInstanceStorage} from "../../../GlobalSingle"
import {ErrorBoundary} from "../../../components/ErrorBoundary"

const Security = ({ accountUrl, language }) => {
    const iframeUrl = accountUrl.replace(/\/account(\?.*)?$/, "/account/account-security/signing-in$1")
        + `&kc_locale=${language}`;

    return (
        <Container fluid={true}>
            <iframe
                title="Security Settings"
                src={iframeUrl}
                style={{ width: "100%", height: "880px", borderStyle: "none" }}
            />
        </Container>
    );
};

export function SecurityPage(props) {
    const accountUrl = globalInstanceStorage.getKeycloak().createAccountUrl();
    return <>
        <Row>
            <Col>
                <ErrorBoundary>
                    <Security accountUrl={accountUrl} language={"en"}/>
                </ErrorBoundary>
            </Col>
        </Row>

    </>;
}
